import {
    Button,
    Label,
    Row,
    Col,
    FormGroup,
    CardBody,
    Fade,
    FormText,
    Spinner,
    Alert,
    Card,
    Collapse
} from 'reactstrap';

// Cusotm Elements

// Card images
import cardVisa from "../../../assets/images/VISA.png";
import cardMaster from "../../../assets/images/MasterCard.png";
import cardAmex from "../../../assets/images/AMEX.png";
import cvvImage from "../../../assets/images/cvv.png";
import { AxiosCancelAll } from "../../../api/CancellableAPI";
import { Paysafe } from "@spordle/mini-gateway";
import Translate from "@spordle/intl-elements";
import { useContext, useEffect, useState } from 'react';
import { PaymentContext } from '../../../contexts/PaymentContext';

const PaymentCard = (props) => {
    const {
        paymentInfo,
        nextStep,
        previousStep,
        completedSteps,
        invalidPaysafeToken,
        setInvalidPaysafeToken,
    } = useContext(PaymentContext);

    const [ showCVVHelper, setShowCVVHelper ] = useState(false);

    const getCardBrand = (name) => {
        switch (name){
            case 'American Express':
                return cardAmex;
            case 'MasterCard':
                return cardMaster;
            case 'Visa':
                return cardVisa;
            default:
                return '';
        }
    }

    useEffect(() => {
        return () => {
            AxiosCancelAll();
        }
    }, []);

    return (
        <CardBody>
            <Paysafe
                product={paymentInfo.product}
                options={{
                    style: {
                        input: {
                            "font": "400 14px Arial",
                            "font-family": "Montserrat, sans-serif",
                            "line-height": "1.5",
                            "color": "#525f7f",
                        },
                    },
                }}
            >
                {({ CardNumber, Cvv, ExpirationDate }, paysafe, consumer) => {
                    const loadingPaysafe = paysafe.loadingPaysafe;
                    return (
                        <>
                            <Fade in={loadingPaysafe} className={loadingPaysafe ? 'text-center' : 'd-none'}>
                                <Spinner className="mt-5 mb-5" color="secondary" style={{ width: '80px', height: '80px' }} type="grow" role="status" />
                            </Fade>
                            <Fade in={!loadingPaysafe} className={loadingPaysafe ? 'd-none' : ''}>
                                {invalidPaysafeToken &&
                                <Alert color="danger">
                                    <Translate id="paymentReview.resetCart.description" />
                                </Alert>
                                }
                                <h1 className="h3 mb-3 font-bold"><Translate id="payment.cardInfo.form.title" /></h1>
                                <FormGroup>
                                    <Label for={"card-number"}><Translate id="payment.cardInfo.form.label.cardNumber" /></Label>
                                    <div style={{ position: "relative" }}>
                                        <CardNumber className='form-control' />
                                        <div style={{ position: "absolute", right: "10px", top: "6px" }}>
                                            <img id="paysafeCard" src={getCardBrand(paysafe.cardBrand)} style={{ height: "22px" }} alt="" />
                                        </div>
                                    </div>
                                </FormGroup>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="expiration-date"><Translate id="payment.cardInfo.form.label.expirationDate" /></Label>
                                            <ExpirationDate className='form-control' />
                                            <FormText color="muted"><Translate id="payment.cardInfo.form.helper.expirationDate" /></FormText>
                                        </FormGroup>
                                    </Col>

                                    <Col>
                                        <FormGroup>
                                            <Label for="cvv"><Translate id="payment.cardInfo.form.label.cvv" /></Label>
                                            <Cvv className='form-control' />
                                            <FormText color="muted"><a href="#CVV" onClick={() => setShowCVVHelper(!showCVVHelper)}><i className="mr-1 mdi mdi-alert-circle-outline" /><Translate id="payment.cardInfo.form.helper.cvv" /></a></FormText>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Collapse isOpen={showCVVHelper} className='text-center'>
                                    <Card body className='border card-shadow'>
                                        <Label><i className='mdi mdi-information-outline text-warning mr-1' /><Translate id="payment.cardInfo.form.helper.findCvv" /></Label>
                                        <div className='text-center mt-1'><img src={cvvImage} width={"60%"} /></div>
                                    </Card>
                                </Collapse>
                                {/* <FormGroup>
                                    <Col>
                                        <Terms
                                            translateLabel={false} label={
                                                <>
                                                    <Translate id="payment.cardInfo.form.label.terms.1" />
                                                    <a href="#terms"><Translate id="payment.cardInfo.form.label.terms.2" /></a>
                                                    <Translate id="payment.cardInfo.form.label.terms.3" />
                                                    <a href="#terms"><Translate id="payment.cardInfo.form.label.terms.4" /></a>
                                                </>
                                            }
                                        />
                                    </Col>
                                </FormGroup> */}
                                {consumer.formik.status &&
                                    <div className='alert alert-danger'><Translate id={`payment.${consumer.formik.status}`} /></div>
                                }
                                <div className="text-right mt-4">
                                    {!completedSteps ?
                                        <Button color="outline-secondary" size="lg" onClick={() => previousStep()} className="ml-3"><Translate id="misc.action.back" /></Button>
                                        : null}
                                    <Button
                                        color="primary"
                                        className="ml-3"
                                        size="lg"
                                        disabled={consumer.formik.isSubmitting}
                                        onClick={() => {
                                            setInvalidPaysafeToken(false);

                                            consumer.formik.setFieldValue("terms", true); // bypass while we wait to implement terms check

                                            consumer.formik.setSubmitting(true);
                                            consumer.validateDisplayedFields().then((hasError) => {
                                                if(!hasError){
                                                    consumer.tokenize().then((_success) => {
                                                        consumer.formik.setSubmitting(false);
                                                        consumer.formik.setStatus();
                                                        nextStep();
                                                    }, (error) => {
                                                        consumer.formik.setStatus("paysafe.tokenize.error." + error.error.code);
                                                        consumer.formik.setSubmitting(false);
                                                    });
                                                }else{
                                                    consumer.formik.setSubmitting(false);
                                                }
                                            });
                                        }}
                                    >
                                        <Translate id="misc.action.next" />
                                    </Button>
                                </div>
                            </Fade>
                        </>
                    )
                }}
            </Paysafe>
        </CardBody>
    );
}

export default PaymentCard;